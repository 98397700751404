<template>
  <div class="px-3 px-lg-5">
    <div class="row">
      <div class="col-12 pt-2 pb-3">
        <a
          href="javascript:;"
          class="robo-12-500 txt-grey-900-blur text-decoration-none d-flex align-items-center mb-3"
          @click="goToList()"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.2071 16.2071C14.5976 15.8166 14.5976 15.1834 14.2071 14.7929L11.4142 12L14.2071 9.20711C14.5976 8.81658 14.5976 8.18342 14.2071 7.79289C13.8166 7.40237 13.1834 7.40237 12.7929 7.79289L9.29289 11.2929C8.90237 11.6834 8.90237 12.3166 9.29289 12.7071L12.7929 16.2071C13.1834 16.5976 13.8166 16.5976 14.2071 16.2071Z"
              fill="#8F90A6"
            />
          </svg>
          {{ $t('lbl_back_to_list') }}
        </a>
        <div class="header-title">
          <span class="robo-24-500 text-white">{{ $t('lbl_update_template') }}</span>
        </div>
      </div>
      <div class="col-12 mb-3">
        <label for class="robo-20-500 txt-pri">
          <svg
            class="mr-2"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 5.32998C13.01 3.97998 14.63 3.09998 16.44 3.09998C19.51 3.09998 22 5.59998 22 8.68998C22 9.59377 21.8904 10.4457 21.6931 11.25H17.4684C17.3942 11.25 17.3238 11.217 17.2763 11.16L16.055 9.69448C15.2471 8.72498 13.7098 8.9034 13.1454 10.0322L11.6266 13.0698C11.5393 13.2444 11.2945 13.256 11.191 13.0905L9.92975 11.0725C9.60996 10.5608 9.04913 10.25 8.44575 10.25H2.11193C2.03885 9.74778 2 9.22808 2 8.68998C2 5.59998 4.49 3.09998 7.56 3.09998C9.37 3.09998 10.99 3.97998 12 5.32998ZM2.44243 11.75C2.46735 11.8339 2.49321 11.9172 2.52 12C4.1 17 8.97 19.99 11.38 20.81C11.72 20.93 12.28 20.93 12.62 20.81C14.907 20.0318 19.4092 17.2996 21.2131 12.75H17.4684C16.949 12.75 16.4565 12.5193 16.124 12.1203L14.9027 10.6548C14.7873 10.5163 14.5677 10.5417 14.487 10.703L12.9682 13.7406C12.3571 14.9629 10.6433 15.0444 9.91898 13.8855L8.65775 11.8675C8.61207 11.7944 8.53195 11.75 8.44575 11.75H2.44243Z"
              fill="#20419B"
            />
          </svg>
          {{ $t('lbl_diagnosis') }}
        </label>
      </div>
      <div class="col-12 mb-3">
        <label for class="robo-16-500 txt-muted mb-2">{{ $t('lbl_disease_name') }}</label>
        <input type="text" class="form-control bg-blue-gray" v-model="form.diseases_name" />
        <small class="text-red" v-if="err_name">* {{err_name}}</small>
      </div>
      <!-- <div class="col-12 mb-3">
        <label
          for=""
          class="robo-16-500 txt-muted mb-2"
        >Diễn biến bệnh</label>
        <textarea
          class="form-control bg-blue-gray"
          placeholder="Diễn biến bệnh như thế nào?"
          v-model="form.diseases_stage"
          style="height: 100px;"
        ></textarea>
      </div>-->
    </div>

    <SettingStats
      :template="template"
      @changeSStats="changeSStats"
      @changeCurrSymp="changeCurrSymp"
      @changeSymp="changeSymp"
    />
    <div class="d-flex justify-content-center align-items-center my-5">
      <button
        class="btn bg-pri bd-pri text-white px-5 radius-0 w-25"
        @click="onSubmit()"
        :disabled="loading"
      >Lưu</button>
    </div>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import SettingStats from '../../../components/Diseases/SettingStats.vue'
const cycle_units = [
  { id: 2, text: 'Ngày' },
  { id: 1, text: 'Giờ' },
  { id: 3, text: 'Tuần' },
  { id: 4, text: 'Tháng' }
]

export default {
  name: 'TemplateClone',
  components: { vSelect, SettingStats },
  props: [],
  data () {
    return {
      form: {
        diseases_name: '',
        diseases_stage: '',
        note: ''
      },
      err_name: '',
      loading: false,
      cycle_units,
      survival_stats: [],
      curr_survival_stats: [],
      symptoms: [],
      curr_symptoms: [],
      search_stat: '',
      template: null
    }
  },
  watch: {},
  mounted () {
    if (this.$route?.params?.id) this.getTemplateDetail()
  },
  methods: {
    goToList () {
      this.$router.push(`/doctor/clinic?type=templates`)
    },
    changeSymp (data) {
      console.log(data)
      this.symptoms = data
    },
    changeCurrSymp (data) {
      this.curr_symptoms = data
    },
    changeSStats (data) {
      this.survival_stats = data
    },
    async getTemplateDetail () {
      let self = this
      await self.$rf
        .getRequest('DoctorRequest')
        .showTemplate(self.$route?.params?.id)
        .then(resp => {
          if (resp && resp.data) {
            self.template = resp.data
            self.form = {
              diseases_name: resp.data.diseases_name,
              diseases_stage: resp.data.diseases_stage,
              note: resp.data.note
            }
          }
        })
    },
    async onSubmit () {
      let self = this
      if (self.loading) return
      if (!self.validateStats() && !self.validateSymptoms()) {
        self.loading = true
        await self.onSave().then(() => {
          self.loading = false
          self.$toast.open({
            message: 'Thành công',
            type: 'success'
          })
          self.$router.push(`/doctor/clinic?type=templates`)
        })
      } else {
        alert('Vui lòng nhập đủ các thông tin yêu cầu!')
      }
    },
    async onSave () {
      let self = this
      let resp = await self.onSaveTemplate()
      if (resp.data) {
        await self.onSaveStats(resp.data.id)
        await self.onSaveSymptoms(resp.data.id)
      }
    },
    async onSaveTemplate () {
      let self = this
      if (!self.form.diseases_name) {
        alert('Vui lòng nhập tên bệnh')
        return
      }
      let clinic_id = parseInt(this.$clinicId)
      let data = {
        diseases_name: self.form.diseases_name,
        diseases_stage: self.form.diseases_stage,
        note: self.form.note,
        clinic_id: clinic_id,
        delete_symptoms: 1,
        delete_stats: 1
      }
      if (
        self.template &&
        self.template.clinic_id &&
        clinic_id &&
        self.template.clinic_id === clinic_id
      ) {
        let resp = self.$rf
          .getRequest('DoctorRequest')
          .updateTemplate(self.template.id, data)
        return resp
      } else {
        let resp = self.$rf.getRequest('DoctorRequest').createTemplates(data)
        return resp
      }
    },
    async onSaveSymptoms (id) {
      let self = this
      // add
      if (!self.curr_symptoms.length && !self.symptoms.length) return
      let new_symptoms = []
      self.curr_symptoms.map(symp => {
        if (symp.is_monitor) {
          symp.is_monitor = 1
        } else {
          symp.is_monitor = 0
        }
        new_symptoms.push(symp)
      })
      self.symptoms.map(symp => {
        if (symp.is_monitor) {
          symp.is_monitor = 1
        } else {
          symp.is_monitor = 0
        }
        symp.person_id = self.person_id
        symp.diseases_templates_id = id
        new_symptoms.push(symp)
      })
      new_symptoms = new_symptoms.filter(
        item => item.symptom_name !== '' && item.symptom_name !== null
      )
      if (new_symptoms.length > 0) {
        let params = { symptoms: new_symptoms }
        await self.$rf
          .getRequest('DoctorRequest')
          .multiCreateDiseasesTemplateSymtomps(id, params)
      }
    },
    async onSaveStats (id) {
      let self = this
      let new_stats = []
      self.survival_stats.map(stat => {
        let new_stat = {
          code: stat.code,
          diseases_templates_id: id,
          min_threshold: parseFloat(stat.min_threshold),
          max_threshold: parseFloat(stat.max_threshold),
          cycle: stat.cycle,
          cycle_unit: stat.cycle_unit,
          unit: stat.unit,
          is_monitor: stat.is_monitor ? 1 : 0
        }
        new_stats.push(new_stat)
      })
      if (new_stats.length > 0) {
        let params = { stats: new_stats }
        await self.$rf
          .getRequest('DoctorRequest')
          .multiCreateDiseasesTemplateMonitorStats(id, params)
      }
    },
    validateStats () {
      let self = this
      return self.survival_stats.filter(
        stats =>
          stats.is_monitor &&
          stats.stat &&
          (stats.min_threshold === '' ||
            stats.max_threshold === '' ||
            isNaN(stats.min_threshold) ||
            isNaN(stats.max_threshold) ||
            parseFloat(stats.min_threshold) > parseFloat(stats.max_threshold) ||
            parseFloat(stats.min_threshold) < 0 ||
            parseFloat(stats.max_threshold) < 0 ||
            stats.cycle === '' ||
            isNaN(stats.cycle))
      ).length
    },
    validateSymptoms () {
      return this.symptoms.filter(symp => !symp.symptom_name).length
    }
  }
}
</script>
<style scoped="true">
.radius-0 {
  border-radius: 0;
}
.header-title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #20419b;
  color: white;
  height: 64px;
}
</style>